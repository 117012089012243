@font-face {
  font-family: 'Material Icons';
  src: url('~assets/font/materialicons/materialicons-regular.eot');
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url('~assets/font/materialicons/materialicons-regular.eot?#iefix') format('embedded-opentype'),
       url('~assets/font/materialicons/materialicons-regular.woff2') format('woff2'),
       url('~assets/font/materialicons/materialicons-regular.woff') format('woff'),
       url('~assets/font/materialicons/materialicons-regular.ttf') format('truetype'),
       url('~assets/font/materialicons/materialicons-regular.svg#MaterialIcons') format('svg');
  font-weight: 400;
  font-style: normal;
}
