

.invisible-table {
  --invisible-table-border-color: transparent;
  --invisible-table-font-weight-bold: var(--font-weight-bold);
  --invisible-table-heading-color: var(--color-text-weak);
  --invisible-table-heading-primary-color: var(--color-primary);
  border: {
    collapse: collapse;
    color: var(--invisible-table-border-color);
    style: none;
    width: 0;
  }
  width: auto;

  // All columns except the second one are 1px wide.
  tr > :where(:nth-child(1), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6)) {
    width: 1px;
  }

  // The second column is flexible.
  tr > :where(:nth-child(2)) {
    width: auto;
  }

  th {
    font-weight: var(--invisible-table-font-weight-bold);
  }

  th,
  td {
    border: {
      color: var(--invisible-table-border-color);
      style: none;
      width: 0;
    }
    padding-block: 2px;
    padding-inline: 5px;
    text-align: left;
    vertical-align: middle;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .vertical-align-bottom {
    vertical-align: bottom;
  }

  .heading {
    color: var(--invisible-table-heading-color);
    font-weight: var(--invisible-table-font-weight-bold);
    padding-bottom: 20px;
    vertical-align: bottom;
  }

  .heading-primary {
    color: var(--invisible-table-heading-primary-color);
    font-weight: var(--invisible-table-font-weight-bold);
    padding-bottom: 20px;
  }

  .separator > :where(th, td) {
    padding-top: 50px;
  }

  .label-with-padding {
    padding-left: 70px;
  }

  .field-with-prefix {
    align-items: center;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 40px 140px;
  }

  .field-with-prefix__prefix {
    align-items: center;
    display: flex;
    font-weight: var(--font-weight-bold);
    justify-content: end;
  }
}

.cols-6-container {
  container: {
    name: table-cols-6;
    type: inline-size;
  }
  overflow-x: auto;
  width: 100%;
}

.cols-6 {
  --cols-6-col-width: var(--cols-6-col-width-dynamic, 150px);
  tr {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      min-width: var(--cols-6-col-width);
      max-width: var(--cols-6-col-width);
      width: var(--cols-6-col-width);
    }
  }
}

@container table-cols-6 (max-width: 1249px) {
  .cols-6 {
    --cols-6-col-width-dynamic: 150px;
  }
}

@container table-cols-6 (min-width: 1250px) {
  .cols-6 {
    --cols-6-col-width-dynamic: 200px;
  }
}

.cols-6-alt-container {
  container: {
    name: table-cols-6-alt;
    type: inline-size;
  }
  overflow-x: auto;
  width: 100%;
}

.cols-6-alt {
  --cols-6-col-width: var(--cols-6-col-width-dynamic, 150px);
  --cols-6-col-alt-width: var(--cols-6-col-alt-width-dynamic, 50px);
  tr {
    td:nth-child(4),
    td:nth-child(6) {
      min-width: var(--cols-6-col-width);
      max-width: var(--cols-6-col-width);
      width: var(--cols-6-col-width);
    }
  }

  tr {
    td:nth-child(3),
    td:nth-child(5) {
      font-weight: var(--font-weight-bold);
      text-align: right;

      min-width: var(--cols-6-col-alt-width);
      max-width: var(--cols-6-col-alt-width);
      width: var(--cols-6-col-alt-width);
    }
  }
}

@container table-cols-6-alt (max-width: 1249px) {
  .cols-6-alt {
    --cols-6-col-width-dynamic: 150px;
    --cols-6-col-alt-width-dynamic: 50px;
  }
}

@container table-cols-6-alt (min-width: 1250px) {
  .cols-6-alt {
    --cols-6-col-width-dynamic: 200px;
    --cols-6-col-alt-width-dynamic: 50px;
  }
}
