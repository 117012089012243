* {
  &:not(html),
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

iframe {
  width: 100%;
}

fieldset {
  min-width: unset;
}
