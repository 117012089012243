/* Override Flexmonster CSS */

@font-face {
  font-family: "flexmonster-icons";
  src: url("../../font/flexmonster/flexmonster-icons.woff"),
  url("../../font/flexmonster/flexmonster-icons.ttf"),
  url("../../font/flexmonster/flexmonster-icons.svg");
  font-weight: normal;
  font-style: normal;
}


/* Modal title and buttons layout fix. */
/* Override Flexmonster CSS */
#fm-toolbar-wrapper div.fm-panel .fm-panel-content .fm-title-bar {
  display: flex;
}

#fm-toolbar-wrapper div.fm-panel .fm-panel-content .fm-title-bar .fm-title-text {
  flex-grow: 1;
  margin-right: 20px;
  white-space: initial;
}

#fm-toolbar-wrapper div.fm-panel .fm-panel-content .fm-title-bar .fm-toolbox {
  display: flex;
  position: initial;
  right: auto;
}

/* Radio button - checked center. */
#fm-toolbar-wrapper .fm-toolbar-ui .fm-radio-wrap label:after {
  left: 4px;
}


/* CHARTS COLORS */
.fm-charts-color-1 {
  fill: #ed1c24 !important;
}

.fm-charts-color-2 {
  fill: #175473 !important;
}

.fm-charts-color-3 {
  fill: #fea223 !important;
}

.fm-charts-color-4 {
  fill: #2D836A !important;
}

.fm-charts-color-5 {
  fill: #36468F;
}

.fm-charts-color-6 {
  fill: #3DBFB8 !important;
}

.fm-charts-color-7 {
  fill: #4A639F !important;
}

.fm-charts-color-8 {
  fill: #FFD600 !important;
}

.fm-charts-color-9 {
  fill: #6191AF !important;
}

.fm-charts-color-10 {
  fill: none;
}

#fm-toolbar-wrapper #fm-tab-disabled {
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
}

fm-pivot > div {
  height: 100%;
}

/* TABLE TOOLBAR ICONS */
#fm-toolbar-wrapper {

  #fm-toolbar {

    /* Toolbar icons */
    li {
      width: 75px;

      .fm-svg-icon {
        svg {
          height: 30px;
          width: 30px;
        }
      }

      .fm-svg-icon + span {
        margin-top: 5px;
      }
      span {
        font-size: 13px;
      }
    }

    /* Dropdown icons */
    .fm-dropdown-content {
      li {
        align-items: center;
        display: flex;
        width: 160px;
        .fm-svg-icon {
          left: 5px;
          right: 5px;
          svg {
            height: 25px;
            width: 25px;
          }
        }
      }
    }
  }

  /* Scroll icons */
  .fm-left-scroll-button {
    &.fm-scroll-arrow {
      margin-top: 35px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #555;
    }
  }

  .fm-list-wrapper {
    &.fm-one-arrow-scroll {
      width: calc(100% - 15px) !important;
    }

    &.fm-two-arrow-scroll {
      width: calc(100% - 30px) !important;
    }
  }

  .fm-right-scroll-button {
    &.fm-scroll-arrow {
      margin-top: 35px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #555;
    }
  }

}


/* OPEN FIELDS ICON */
#fm-pivot-view {
  .fm-fields-view-wrap {
    button {
      &.fm-btn-open-fields {
        &::before {
          content: "\e904";
        }
      }
    }
  }
}


/* FIELDS DIALOG */

/* Fields header */
#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-header .fm-ui-col:first-child {
  margin-right: initial;
  margin-bottom: 10px;
  max-width: 100%;
}

#fm-pivot-view div.fm-ui-window .fm-popup-header .fm-ui-btns-row {
  position: initial;
  right: initial;
  text-align: right;
  margin-bottom: 0;
}

/* Fields content */
#fm-pivot-view .fm-fields-view .fm-popup-content {
  height: calc(100% - 117px);
}

#fm-pivot-view .fm-fields-view.fm-flat-fields .fm-popup-content .fm-list-wrap {
  min-height: 294px;
}


/* Fields calculated view header */
#fm-pivot-view .fm-ui .fm-vam {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

#fm-pivot-view div.fm-calculated-view .fm-popup-header .fm-ui-col:first-child {
  margin-right: 0;
  margin-bottom: 10px;
}

#fm-pivot-view div.fm-calculated-view .fm-popup-header .fm-popup-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#fm-pivot-view div.fm-ui-window .fm-popup-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/* Pivot view header */
#fm-pivot-view .fm-fields-view .fm-popup-header .fm-ui-col:first-child {
  margin-bottom: 10px;
  max-width: 100%;
}

#fm-pivot-view .fm-fields-view.fm-layout-tablet .fm-popup-header .fm-ui-col:first-child {
  margin-right: 0;
}

#fm-pivot-view .fm-filter-view .fm-filters-table {
  height: calc(100% - 150px);
}

#fm-pivot-view .fm-filter-view .fm-filters-table .fm-filters-table-content .fm-members-filter-list li .fm-ui-checkbox {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}
