.c-form__wrapper {
  min-width: 400px;
}

.c-form {}

.c-form__field--full {
  width: 100%;
}

.c-form__button--full {
  width: 100%;
}

.c-form__button--wide {
  padding: {
    left: 50px !important;
    right: 50px !important;
  }
}
