.u-disabled {
  --u-disabled-opacity: var(--opacity-disabled);
  cursor: none;
  opacity: var(--u-disabled-opacity);
  pointer-events: none;
  user-select: none;
}

.u-dragging {
  opacity: 0.5 !important;
}

.u-radius-5 {
  border-radius: 5px;
}

.u-background-weak {
  --u-background-weak-background-color: var(--color-background-weak);
  background-color: var(--u-background-weak-background-color);
}

.bui-drop-zone {
  --bui-drop-zone-border-color: var(--color-transparent);
  height: 100%;
  min-height: 40px;
  position: relative;

  &::before {
    border: {
      color: var(--bui-drop-zone-border-color);
      style: dashed;
      width: 2px;
    }
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.bui-drop-zone--available {
  --bui-drop-zone-border-color: var(--color-border-normal);
}

.bui-drop-zone--active {
  --bui-drop-zone-border-color: var(--color-accent);
}

.u-pointer-events-none {
  pointer-events: none;
}
