@font-face {
  font-family: 'Roboto';
  src: url('../../../font/roboto/roboto-regular.eot');
  src: url('../../../font/roboto/roboto-regular.eot?#iefix') format('embedded-opentype'),
       url('../../../font/roboto/roboto-regular.woff2') format('woff2'),
       url('../../../font/roboto/roboto-regular.woff') format('woff'),
       url('../../../font/roboto/roboto-regular.ttf') format('truetype'),
       url('../../../font/roboto/roboto-regular.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: normal;
}
