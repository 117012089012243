.dx-area-icon-all {
  display: none;
}

.dx-area .dx-area-fields {
  background-color: #FFFFFF;
  border-radius: 10px;
  margin-top: 15px;
  padding: 2px 10px 10px 10px;
}

.dx-treeview-border-visible .dx-scrollable-content > .dx-treeview-node-container > li {
  padding: 0;
}

.dx-treeview-item-with-checkbox .dx-checkbox {
  border: 3px solid var(--color-text-weak);
  border-radius: 2px;
  left: 5px;
  top: 1px;
  transform: scale(0.6);
}

.dx-treeview-item-with-checkbox .dx-treeview-item {
  color: var(--color-text-weak);
}

.dx-searchbox .dx-texteditor-input {
  background-color: var(--color-background-weak);
}

.dx-placeholder {
  font-weight: bold;
}

.dx-pivotgridfieldchooser .dx-area-fields[group] {
  background-color: white;
}

.dx-area-icon-row {
  display: none;
}

.dx-texteditor-buttons-container {
  background-color: var(--color-background-weak);
}

.dx-area-icon-column {
  display: none;
}

.dx-area-icon-data {
  display: none;
}

.dx-area-icon-filter {
  display: none;
}

.dx-area-fields-header .dx-area-caption {
  color: var(--color-primary);
  font-size: 14px;
  font-weight: normal;
}

.dx-area > .dx-scrollable > .dx-scrollable-wrapper {
  padding-top: 10px;
}

.dx-area > .dx-scrollable > .dx-scrollable-wrapper .dx-area-field {
  background-color: var(--color-background-weak);
  border: none;
  color: var(--color-text-weak);
  font-weight: bold;
}

.dx-area > .dx-scrollable > .dx-scrollable-wrapper .dx-sort-up {
  color: var(--color-text-weak);
}

.dx-area > .dx-scrollable > .dx-scrollable-wrapper .dx-sort {
  color: var(--color-text-weak);
}

.dx-area > .dx-scrollable > .dx-scrollable-wrapper .dx-header-filter {
  color: var(--color-text-weak);
  font-weight: bold;
}
