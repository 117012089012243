@use "settings/variables/visuals" as *;

:root {
  @each $opacity, $value in $opacities {
    --opacity-#{$opacity}: #{$value};
  }

  @each $box-shadow, $value in $box-shadows {
    --box-shadow-#{$box-shadow}: #{$value};
  }
}
