.mat-dialog-container {
  --mat-dialog-container-space-x: var(--space-l);
  --mat-dialog-container-space-y: var(--space-s);
  border-radius: 20px !important;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
              0 2px 3px 0px rgba(0, 0, 0, 0.14),
              0px 8px 8px 0px rgba(0, 0, 0, 0.12) !important;
  padding: {
    bottom: var(--mat-dialog-container-space-y) !important;
    left: var(--mat-dialog-container-space-x) !important;
    right: var(--mat-dialog-container-space-x) !important;
    top: var(--mat-dialog-container-space-y) !important;
  }
}

.dialog-fixed-height {
  --dialog-fixed-height-template-dialog-body-height: 100%;
  height: calc(100vh - 50px);
}
