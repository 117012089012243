.c-layout-content-main__body:has(.c-master-data-items-table__filter-row) .c-beyond-table {

  .dx-datagrid-headers {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--color-background-normal);
    padding-right: 7px;
  }

  .dx-texteditor.dx-editor-outlined {
    border-radius: 0;
  }

  .dx-datagrid-columns-separator-transparent {
    background-color: var(--color-background-normal);
  }

  .dx-datagrid-headers .dx-texteditor-input,
  .dx-datagrid-rowsview .dx-texteditor-input {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .dx-datagrid .dx-datagrid-headers .dx-column-lines > td:not(:last-child) {
    border-right: 2px solid var(--color-background-normal);
  }

  .dx-datagrid-columns-separator {
    width: 1px;
  }

  .dx-datagrid .dx-column-lines > td:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    background-color: var(--dx-datagrid-table-head-background-color);
  }

  .dx-datagrid-focus-overlay {
    height: 3em !important;
  }

  .dx-datagrid-headers .dx-datagrid-table tr:nth-child(1) > td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .dx-datagrid-headers .dx-datagrid-table tr:nth-child(2) > td:last-child.dx-command-edit {
    background-color: var(--color-background-normal);
    border-left: 1px solid var(--color-background-normal);
  }

  .dx-datagrid-headers .dx-datagrid-content {
    border-bottom: 2px solid rgba(var(--color-primary-500-rgb));
  }
}
