@use "settings/variables/layout" as *;

@each $key, $value in $icon-sizes {
  .u-icon-size-#{$key} {
    --u-icon-size-#{$key}: var(--icon-size-#{$key});
    height: var(--u-icon-size-#{$key}) !important;
    line-height: var(--u-icon-size-#{$key}) !important;
    width: var(--u-icon-size-#{$key}) !important;
  }
}

.u-icon-color-primary {
  color: var(--color-icon-primary);
}

.u-icon-color-accent {
  color: var(--color-icon-accent);
}

.u-icon-color-normal {
  color: var(--color-icon-normal);
}

.u-icon-color-weak {
  color: var(--color-icon-weak);
}

.u-icon-color-inverted {
  color: var(--color-icon-inverted);
}

.u-icon-color-text-normal {
  color: var(--color-icon-text-normal);
}

.u-icon-color-text-weak {
  color: var(--color-icon-text-weak);
}

.u-icon-color-text-inverted {
  color: var(--color-icon-text-normal-inverted);
}

