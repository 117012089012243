.c-draggable__list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.c-draggable__list-item {
  align-items: center;
  column-gap: 10px;
  display: grid;
}

.c-draggable__list-item:not(.c-draggable__list-item--with-actions) {
  grid-template-columns: min-content auto;
}

.c-draggable__list-item--with-actions {
  grid-template-columns: min-content auto min-content;
}

.c-draggable__list-item-handle {
  cursor: move;
}

.cdk-drag-preview {
  background-color: var(--color-background-normal);
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
}

.cdk-drag-placeholder.c-draggable__list-item-wrapper {
  .c-draggable__list-item {
    opacity: 0;
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.c-draggable__list.cdk-drop-list-dragging .c-draggable__list-item-wrapper:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
