@font-face {
  font-family: 'Roboto';
  src: url('../../../font/roboto/roboto-700italic.eot');
  src: url('../../../font/roboto/roboto-700italic.eot?#iefix') format('embedded-opentype'),
       url('../../../font/roboto/roboto-700italic.woff2') format('woff2'),
       url('../../../font/roboto/roboto-700italic.woff') format('woff'),
       url('../../../font/roboto/roboto-700italic.ttf') format('truetype'),
       url('../../../font/roboto/roboto-700italic.svg#Roboto') format('svg');
  font-weight: 700;
  font-style: italic;
}
