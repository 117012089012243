.mat-tab-body-wrapper {
  padding-top: var(--space-m);
}

.c-beyond-tabs > .mat-tab-body-wrapper {
  padding-top: 0 !important;
  overflow-y: auto;
  height: 100%;

  .is-touch-device & {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }
}

.mat-tab-label {
  max-width: 250px !important;
  opacity: 1 !important;
  padding: 0 !important;
  height: 35px !important;
}

.c-beyond-tabs .mat-tab-label {
  display: grid !important;
  grid-template-columns: auto !important;
  justify-content: unset;

  &:not(:last-child) {
    .mat-tab-label-content {
      position: relative;
    }

    .c-beyond-tabs__tab-content {
      &::after {
        --c-beyond-tabs-separator-distance: 8px;
        background-color: var(--color-background-medium);
        bottom: var(--c-beyond-tabs-separator-distance);
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        top: var(--c-beyond-tabs-separator-distance);
        width: 1px;
      }
    }
  }
}

.c-beyond-tabs .mat-tab-label-content {
  display: block !important;
}

.c-beyond-tabs__tab-content-actions .mat-button-wrapper {
  align-items: center !important;
  display: flex;
  height: 34px !important;
  justify-content: center;
  width: 40px;
}

.c-beyond-tabs .mat-tab-header-pagination-chevron {
  border-color: var(--color-icon-text-weak);
}

.c-beyond-tabs .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.28);
}
