.u-list-flat {
  list-style: none;
  margin: 0;
  padding: 0;
}

.u-list-flex {
  --u-list-flex-row-gap: var(--space-xxs);
  display: flex;
  flex-direction: column;
  row-gap: var(--u-list-flex-row-gap);
}
