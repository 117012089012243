.mat-button-wrapper {
  --mat-button-font-size: var(--font-size-base, 16px);
  --mat-button-font-weight: var(--font-weight-bold, 700);
  font: {
    size: var(--mat-button-font-size);
    weight: var(--mat-button-font-weight);
  }
}

.mat-fab .mat-button-wrapper {
  padding: 0 !important;
}

.mat-fab .mat-icon.mat-icon-inline {
  height: 42px;
  width: 42px;
}

.dx-row .mat-menu-trigger {
  color: var(--color-text-weak);
}
