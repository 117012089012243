.dx-pager {
  padding-top: 9px;
  padding-bottom: 9px;
}
.dx-pager.dx-light-mode .dx-page-sizes {
  min-width: 42px;
}
.dx-pager.dx-light-mode .dx-page-index {
  min-width: 19px;
}
.dx-pager .dx-pages .dx-page {
  padding: 7px 9px 8px;
}
.dx-pager .dx-pages .dx-separator {
  padding-left: 8px;
  padding-right: 8px;
}
.dx-pager .dx-pages .dx-navigate-button {
  width: 9px;
  height: 17px;
  padding: 9px 13px;
}
.dx-pager .dx-pages .dx-prev-button {
  font: 14px/1 DXIcons;
}
.dx-pager .dx-pages .dx-prev-button::before {
  content: "";
}
.dx-pager .dx-pages .dx-next-button {
  font: 14px/1 DXIcons;
}
.dx-pager .dx-pages .dx-next-button::before {
  content: "";
}
.dx-pager .dx-pages .dx-next-button,
.dx-pager .dx-pages .dx-prev-button {
  font-size: 21px;
  text-align: center;
  line-height: 21px;
}
.dx-pager .dx-pages .dx-next-button::before,
.dx-pager .dx-pages .dx-prev-button::before {
  position: absolute;
  display: block;
  width: 21px;
  top: 50%;
  margin-top: -10.5px;
  left: 50%;
  margin-left: -10.5px;
}
.dx-pager .dx-page,
.dx-pager .dx-page-size {
  border-radius: 1px;
  border: 1px solid transparent;
}
.dx-pager .dx-page-sizes .dx-page-size {
  padding: 7px 9px 8px 10px;
}
.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  color: var(--dx-datagrid-pager-text-color);
  border-color: transparent;
  background-color: var(--dx-datagrid-pager-text-background-color);
}
