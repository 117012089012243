@use "settings/variables/layout" as *;

:root {
  @each $name, $value in $spaces {
    --space-#{$name}: #{$value};
  }

  @each $name, $value in $icon-sizes {
    --icon-size-#{$name}: #{$value};
  }

  --grid-gap-default: #{$grid-gap-default};

  @each $name, $value in $z-indexes {
    --z-index-#{$name}: #{$value};
  }
}
