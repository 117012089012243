.dx-overlay-content {
  padding: 0 !important;
}

.dx-popup-content {
  height: 100% !important;
}

.dx-popup-content .dx-scrollview-content {
  font-size: var(--font-size-xxxxxs);
}
