@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();

//
$md-warn: (
  50: rgba(var(--color-system-error-50-rgb), 1),
  100: rgba(var(--color-system-error-100-rgb), 1),
  200: rgba(var(--color-system-error-200-rgb), 1),
  300: rgba(var(--color-system-error-300-rgb), 1),
  400: rgba(var(--color-system-error-400-rgb), 1),
  500: rgba(var(--color-system-error-500-rgb), 1),
  600: rgba(var(--color-system-error-600-rgb), 1),
  700: rgba(var(--color-system-error-700-rgb), 1),
  800: rgba(var(--color-system-error-800-rgb), 1),
  900: rgba(var(--color-system-error-900-rgb), 1),
  A100: rgba(var(--color-system-error-A100-rgb), 1),
  A200: rgba(var(--color-system-error-A200-rgb), 1),
  A400: rgba(var(--color-system-error-A400-rgb), 1),
  A700: rgba(var(--color-system-error-A700-rgb), 1),
  contrast: (
    50: rgba(var(--color-system-error-contrast-50-rgb), 1),
    100: rgba(var(--color-system-error-contrast-100-rgb), 1),
    200: rgba(var(--color-system-error-contrast-200-rgb), 1),
    300: rgba(var(--color-system-error-contrast-300-rgb), 1),
    400: rgba(var(--color-system-error-contrast-400-rgb), 1),
    500: rgba(var(--color-system-error-contrast-500-rgb), 1),
    600: rgba(var(--color-system-error-contrast-600-rgb), 1),
    700: rgba(var(--color-system-error-contrast-700-rgb), 1),
    800: rgba(var(--color-system-error-contrast-800-rgb), 1),
    900: rgba(var(--color-system-error-contrast-900-rgb), 1),
    A100: rgba(var(--color-system-error-contrast-A100-rgb), 1),
    A200: rgba(var(--color-system-error-contrast-A200-rgb), 1),
    A400: rgba(var(--color-system-error-contrast-A400-rgb), 1),
    A700: rgba(var(--color-system-error-contrast-A700-rgb), 1),
  )
);

$md-beyondaccent: (
  50: rgba(var(--color-accent-50-rgb), 1),
  100: rgba(var(--color-accent-100-rgb), 1),
  200: rgba(var(--color-accent-200-rgb), 1),
  300: rgba(var(--color-accent-300-rgb), 1),
  400: rgba(var(--color-accent-400-rgb), 1),
  500: rgba(var(--color-accent-500-rgb), 1),
  600: rgba(var(--color-accent-600-rgb), 1),
  700: rgba(var(--color-accent-700-rgb), 1),
  800: rgba(var(--color-accent-800-rgb), 1),
  900: rgba(var(--color-accent-900-rgb), 1),
  A100: rgba(var(--color-accent-A100-rgb), 1),
  A200: rgba(var(--color-accent-A200-rgb), 1),
  A400: rgba(var(--color-accent-A400-rgb), 1),
  A700: rgba(var(--color-accent-A700-rgb), 1),
  contrast: (
    50: rgba(var(--color-accent-contrast-50-rgb), 1),
    100: rgba(var(--color-accent-contrast-100-rgb), 1),
    200: rgba(var(--color-accent-contrast-200-rgb), 1),
    300: rgba(var(--color-accent-contrast-300-rgb), 1),
    400: rgba(var(--color-accent-contrast-400-rgb), 1),
    500: rgba(var(--color-accent-contrast-500-rgb), 1),
    600: rgba(var(--color-accent-contrast-600-rgb), 1),
    700: rgba(var(--color-accent-contrast-700-rgb), 1),
    800: rgba(var(--color-accent-contrast-800-rgb), 1),
    900: rgba(var(--color-accent-contrast-900-rgb), 1),
    A100: rgba(var(--color-accent-contrast-A100-rgb), 1),
    A200: rgba(var(--color-accent-contrast-A200-rgb), 1),
    A400: rgba(var(--color-accent-contrast-A400-rgb), 1),
    A700: rgba(var(--color-accent-contrast-A700-rgb), 1),
  )
);

$md-beyondprimary: (
  50: rgba(var(--color-primary-50-rgb), 1),
  100: rgba(var(--color-primary-100-rgb), 1),
  200: rgba(var(--color-primary-200-rgb), 1),
  300: rgba(var(--color-primary-300-rgb), 1),
  400: rgba(var(--color-primary-400-rgb), 1),
  500: rgba(var(--color-primary-500-rgb), 1),
  600: rgba(var(--color-primary-600-rgb), 1),
  700: rgba(var(--color-primary-700-rgb), 1),
  800: rgba(var(--color-primary-800-rgb), 1),
  900: rgba(var(--color-primary-900-rgb), 1),
  A100: rgba(var(--color-primary-A100-rgb), 1),
  A200: rgba(var(--color-primary-A200-rgb), 1),
  A400: rgba(var(--color-primary-A400-rgb), 1),
  A700: rgba(var(--color-primary-A700-rgb), 1),
  contrast: (
    50: rgba(var(--color-primary-contrast-50-rgb), 1),
    100: rgba(var(--color-primary-contrast-100-rgb), 1),
    200: rgba(var(--color-primary-contrast-200-rgb), 1),
    300: rgba(var(--color-primary-contrast-300-rgb), 1),
    400: rgba(var(--color-primary-contrast-400-rgb), 1),
    500: rgba(var(--color-primary-contrast-500-rgb), 1),
    600: rgba(var(--color-primary-contrast-600-rgb), 1),
    700: rgba(var(--color-primary-contrast-700-rgb), 1),
    800: rgba(var(--color-primary-contrast-800-rgb), 1),
    900: rgba(var(--color-primary-contrast-900-rgb), 1),
    A100: rgba(var(--color-primary-contrast-A100-rgb), 1),
    A200: rgba(var(--color-primary-contrast-A200-rgb), 1),
    A400: rgba(var(--color-primary-contrast-A400-rgb), 1),
    A700: rgba(var(--color-primary-contrast-A700-rgb), 1),
  )
);

$my-app-primary: mat.define-palette($md-beyondprimary, 500, 900, A100);
$my-app-accent:  mat.define-palette($md-beyondaccent, 500, 900, A100);
$my-app-warn:    mat.define-palette($md-warn);
$my-app-dark-theme:   mat.define-dark-theme($my-app-primary, $my-app-accent, $my-app-warn);
$my-app-light-theme:   mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

.material-light-theme {
  @include mat.all-component-themes($my-app-light-theme);
}

.material-dark-theme {
  @include mat.all-component-themes($my-app-dark-theme);
}

.mat-button-wrapper {
  font: {
    size: 16px;
    weight: 700;
  }
}

.mat-form-field-prefix {
  margin-right: 10px;
}

.mat-dialog-actions {
  margin-bottom: 0 !important;
}

.mat-progress-bar {
  height: 8px;
  border-radius: 4px;
}

.mat-progress-bar .mat-progress-bar-element::after {
  border-radius: 4px;
}
