@font-face {
  font-family: "Material Icons";
  src: url("~assets/font/materialicons/materialicons-regular.eot");
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("~assets/font/materialicons/materialicons-regular.eot?#iefix") format("embedded-opentype"), url("~assets/font/materialicons/materialicons-regular.woff2") format("woff2"), url("~assets/font/materialicons/materialicons-regular.woff") format("woff"), url("~assets/font/materialicons/materialicons-regular.ttf") format("truetype"), url("~assets/font/materialicons/materialicons-regular.svg#MaterialIcons") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-regular.eot");
  src: url("../../../font/roboto/roboto-regular.eot?#iefix") format("embedded-opentype"), url("../../../font/roboto/roboto-regular.woff2") format("woff2"), url("../../../font/roboto/roboto-regular.woff") format("woff"), url("../../../font/roboto/roboto-regular.ttf") format("truetype"), url("../../../font/roboto/roboto-regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-italic.eot");
  src: url("../../../font/roboto/roboto-italic.eot?#iefix") format("embedded-opentype"), url("../../../font/roboto/roboto-italic.woff2") format("woff2"), url("../../../font/roboto/roboto-italic.woff") format("woff"), url("../../../font/roboto/roboto-italic.ttf") format("truetype"), url("../../../font/roboto/roboto-italic.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-700.eot");
  src: url("../../../font/roboto/roboto-700.eot?#iefix") format("embedded-opentype"), url("../../../font/roboto/roboto-700.woff2") format("woff2"), url("../../../font/roboto/roboto-700.woff") format("woff"), url("../../../font/roboto/roboto-700.ttf") format("truetype"), url("../../../font/roboto/roboto-700.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-700italic.eot");
  src: url("../../../font/roboto/roboto-700italic.eot?#iefix") format("embedded-opentype"), url("../../../font/roboto/roboto-700italic.woff2") format("woff2"), url("../../../font/roboto/roboto-700italic.woff") format("woff"), url("../../../font/roboto/roboto-700italic.ttf") format("truetype"), url("../../../font/roboto/roboto-700italic.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: url("../../../font/roboto/roboto-300.eot");
  src: url("../../../font/roboto/roboto-300.eot?#iefix") format("embedded-opentype"), url("../../../font/roboto/roboto-300.woff2") format("woff2"), url("../../../font/roboto/roboto-300.woff") format("woff"), url("../../../font/roboto/roboto-300.ttf") format("truetype"), url("../../../font/roboto/roboto-300.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal;
}