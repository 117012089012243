@use "sass:map";
@use "sass:color";
@use "../../settings/mixins/helper" as global-helper-mixins;

// Color palette generated by:
// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5

$beyond-chathamsblue: (
  50 : #e3eaee,
  100 : #b9ccd5,
  200 : #8baab9,
  300 : #5d879d,
  400 : #3a6e88,
  500 : #175473,
  600 : #144d6b,
  700 : #114360,
  800 : #0d3a56,
  900 : #072943,
  A100 : #7abeff,
  A200 : #47a5ff,
  A400 : #148cff,
  A700 : #007ff9,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-chambray: (
  50 : #e7e9f2,
  100 : #c3c8dd,
  200 : #9ba3c7,
  300 : #727eb1,
  400 : #5462a0,
  500 : #36468f,
  600 : #303f87,
  700 : #29377c,
  800 : #222f72,
  900 : #162060,
  A100 : #9aa6ff,
  A200 : #6778ff,
  A400 : #344bff,
  A700 : #1a35ff,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-seagreen: (
  50 : #e6f0ed,
  100 : #c0dad2,
  200 : #96c1b5,
  300 : #6ca897,
  400 : #4d9680,
  500 : #2d836a,
  600 : #287b62,
  700 : #227057,
  800 : #1c664d,
  900 : #11533c,
  A100 : #8bffd3,
  A200 : #58ffbf,
  A400 : #25ffac,
  A700 : #0cffa2,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-hippieblue: (
  50 : #ecf2f5,
  100 : #d0dee7,
  200 : #b0c8d7,
  300 : #90b2c7,
  400 : #79a2bb,
  500 : #6191af,
  600 : #5989a8,
  700 : #4f7e9f,
  800 : #457496,
  900 : #336286,
  A100 : #d1eaff,
  A200 : #9ed3ff,
  A400 : #6bbcff,
  A700 : #52b1ff,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-sanmarino: (
  50 : #e9ecf3,
  100 : #c9d0e2,
  200 : #a5b1cf,
  300 : #8092bc,
  400 : #657aad,
  500 : #4a639f,
  600 : #435b97,
  700 : #3a518d,
  800 : #324783,
  900 : #223572,
  A100 : #b3c3ff,
  A200 : #809bff,
  A400 : #4d73ff,
  A700 : #335fff,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-killarney: (
  50 : #e8eeea,
  100 : #c6d4ca,
  200 : #a0b7a7,
  300 : #799a84,
  400 : #5d8469,
  500 : #406e4f,
  600 : #3a6648,
  700 : #325b3f,
  800 : #2a5136,
  900 : #1c3f26,
  A100 : #82ffa2,
  A200 : #4fff7c,
  A400 : #1cff57,
  A700 : #02ff44,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-treepoppy: (
  50 : #fff4e5,
  100 : #ffe3bd,
  200 : #ffd191,
  300 : #febe65,
  400 : #feb044,
  500 : #fea223,
  600 : #fe9a1f,
  700 : #fe901a,
  800 : #fe8615,
  900 : #fd750c,
  A100 : #ffffff,
  A200 : #fff8f4,
  A400 : #ffd9c1,
  A700 : #ffcaa7,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-accent: (
  50 : #e0f8f7,
  100 : #b3eeea,
  200 : #80e3dd,
  300 : #4dd8cf,
  400 : #26cfc4,
  500 : #3dbfb8,
  600 : #00c1b3,
  700 : #00baab,
  800 : #00b3a3,
  900 : #00a694,
  A100 : #d0fff9,
  A200 : #9dfff2,
  A400 : #6affec,
  A700 : #51ffe9,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-error: (
  50 : #ffeaea,
  100 : #ffcbcb,
  200 : #ffa9a8,
  300 : #ff8685,
  400 : #ff6c6b,
  500 : #ff5251,
  600 : #ff4b4a,
  700 : #ff4140,
  800 : #ff3837,
  900 : #ff2827,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-warning: (
  50 : #fff8e1,
  100 : #ffecb5,
  200 : #ffe083,
  300 : #ffd451,
  400 : #ffca2c,
  500 : #ffc107,
  600 : #ffbb06,
  700 : #ffb305,
  800 : #ffab04,
  900 : #ff9e02,
  A100 : #ffffff,
  A200 : #fffaf2,
  A400 : #ffe4bf,
  A700 : #ffd9a6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$beyond-info: (
  50 : #e2edf9,
  100 : #b6d3f0,
  200 : #86b6e6,
  300 : #5598db,
  400 : #3082d4,
  500 : #0c6ccc,
  600 : #0a64c7,
  700 : #0859c0,
  800 : #064fb9,
  900 : #033dad,
  A100 : #d7e2ff,
  A200 : #a4bdff,
  A400 : #7198ff,
  A700 : #5886ff,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-grayscale: (
  50 : #f2f2f2,
  100 : #eeeeee,
  200 : #d1d1d1,
  300 : #a6a6a6,
  400 : #858585,
  500 : #707070,
  600 : #686868,
  700 : #404040,
  800 : #333333,
  900 : #262626,
  A100 : #FFFFFF,
  A200 : #222222,
  A400 : #111111,
  A700 : #000000,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$beyond-success: (
  50 : #e6f2e6,
  100 : #b3e6c7,
  200 : #80d6a1,
  300 : #4dc67b,
  400 : #26b95f,
  500 : #00ad43,
  600 : #00a63d,
  700 : #009c34,
  800 : #00932c,
  900 : #00831e,
  A100 : #b0ffbb,
  A200 : #7dff90,
  A400 : #4aff64,
  A700 : #30ff4e,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$base-theme-palette: (
  'black': #000000,
  'red':   #ff0200,
  'white': #ffffff,
  'transparent': transparent,
);

@function hex2rgb($hex) {
  $r: color.red($hex);
  $g: color.green($hex);
  $b: color.blue($hex);

  @return quote($r + ', ' + $g + ', ' + $b);
}

@function map-material-colors($name, $palette, $options: ()) {
  $default_options: (
    'default_color': 500
  );

  $options: map.merge($default_options, $options);
  $default_color: map.get($options, 'default_color');

  $map: ();
  $map: map.merge($map, (quote(#{$name}): map.get($palette, $default_color)));

  @each $key, $value in $palette {
    @if ($key != contrast) {
      $map: map.merge($map, (quote(#{$name + '-' + $key}): $value));
      $map: map.merge($map, (quote(#{$name + '-' + $key + '-rgb'}): hex2rgb($value)));
    }
  }

  @each $key, $value in map.get($palette, contrast) {
    $map: map.merge($map, (quote(#{$name + '-contrast-' + $key}): $value));
    $map: map.merge($map, (quote(#{$name + '-contrast-' + $key + '-rgb'}): hex2rgb($value)));
  }

  @return $map;
}

@function colors-list($options: ()) {
  $pre-set-colors-map: ();
  $after-set-colors-map: ();

  @if (map.has-key($options, 'pre-set-colors-map')) {
    $pre-set-colors-map: map.get($options, 'pre-set-colors-map');
  }

  @if (map.has-key($options, 'after-set-colors-map')) {
    $after-set-colors-map:   map.get($options, 'after-set-colors-map');
  }

  //
  // FIXED COLOR VALUES.
  //
  $map: ();

  // Brand.
  $map: map.merge($map, map-material-colors('primary',   $beyond-treepoppy));
  $map: map.merge($map, map-material-colors('secondary', $beyond-accent));
  $map: map.merge($map, map-material-colors('tertiary',  $beyond-chathamsblue));
  $map: map.merge($map, map-material-colors('accent',    $beyond-accent));
  $map: map.merge($map, map-material-colors('grayscale', $beyond-grayscale));

  // System.
  $map: map.merge($map, map-material-colors('system-info',    $beyond-info));
  $map: map.merge($map, map-material-colors('system-error',   $beyond-error));
  $map: map.merge($map, map-material-colors('system-success', $beyond-success));
  $map: map.merge($map, map-material-colors('system-warning', $beyond-warning));

  $map: map.merge($map, ('system-inactive': map.get($beyond-grayscale, 500)));
  $map: map.merge($map, ('red':             map.get($base-theme-palette, 'red')));
  $map: map.merge($map, ('white':           map.get($base-theme-palette, 'white')));
  $map: map.merge($map, ('transparent':     map.get($base-theme-palette, 'transparent')));

  // Text.
  $map: map.merge($map, ('text-normal':          map.get($beyond-grayscale, 800)));
  $map: map.merge($map, ('text-normal-inverted': map.get($beyond-grayscale, A100)));
  $map: map.merge($map, ('text-weak':            map.get($beyond-grayscale, 500)));
  $map: map.merge($map, ('text-weaker':          map.get($beyond-grayscale, 300)));
  $map: map.merge($map, ('text-strong':          map.get($beyond-grayscale, A700)));

  // Link.
  $map: map.merge($map, ('link-normal': map.get($beyond-info, 500)));
  $map: map.merge($map, ('link-hover':  map.get($beyond-info, 200)));

  // Background.
  $map: map.merge($map, ('background-primary-weak':    var(--color-primary-100)));
  $map: map.merge($map, ('background-normal':          var(--color-grayscale-A100)));
  $map: map.merge($map, ('background-normal-rgb':      var(--color-grayscale-A100-rgb)));
  $map: map.merge($map, ('background-normal-inverted': map.get($beyond-grayscale, 800)));
  $map: map.merge($map, ('background-strong':          var(--color-grayscale)));
  $map: map.merge($map, ('background-medium':          map.get($beyond-grayscale, 200)));
  $map: map.merge($map, ('background-weak':            map.get($beyond-grayscale, 50)));
  $map: map.merge($map, ('background-info-weak':       map.get($beyond-info, 50)));

  // Border.
  $map: map.merge($map, ('border-strongest':       map.get($beyond-grayscale, A700)));
  $map: map.merge($map, ('border-strong':          map.get($beyond-grayscale, 800)));
  $map: map.merge($map, ('border-normal':          map.get($beyond-grayscale, 200)));
  $map: map.merge($map, ('border-normal-inverted': map.get($beyond-grayscale, A100)));
  $map: map.merge($map, ('border-weak':            map.get($beyond-grayscale, 50)));
  $map: map.merge($map, ('border-transparent':     map.get($base-theme-palette, 'transparent')));

  // Previous colors are overrideable from outside.
  $map: map.merge($map, $pre-set-colors-map);

  //
  // VARIABLE (depends on some previous color) COLOR VALUES.
  //

  $map: map.merge($map, ('icon-primary':              var(--color-primary)));
  $map: map.merge($map, ('icon-accent':               var(--color-accent)));
  $map: map.merge($map, ('icon-normal':               var(--color-text-normal)));
  $map: map.merge($map, ('icon-weak':                 var(--color-grayscale-400)));
  $map: map.merge($map, ('icon-inverted':             var(--color-text-normal-inverted)));
  $map: map.merge($map, ('icon-text-normal':          var(--color-text-normal)));
  $map: map.merge($map, ('icon-text-normal-inverted': var(--color-text-normal-inverted)));
  $map: map.merge($map, ('icon-text-weak':            var(--color-text-weak)));

  // Form.
  $map: map.merge($map, ('form-input-border-normal':   map.get($beyond-grayscale, 800)));
  $map: map.merge($map, ('form-input-border-hover':    map.get($map, 'form-input-border-normal')));
  $map: map.merge($map, ('form-input-border-focus':    map.get($map, 'form-input-border-normal')));
  $map: map.merge($map, ('form-input-border-disabled': map.get($map, 'form-input-border-normal')));
  $map: map.merge($map, ('form-input-border-error':    map.get($map, 'form-input-border-normal')));
  $map: map.merge($map, ('form-input-border-success':  map.get($map, 'form-input-border-normal')));

  // BUI Button.
  $map: map.merge($map, ('bui-button-background-primary':         map.get($map, 'primary')));
  $map: map.merge($map, ('bui-button-background-primary-hover':   map.get($map, 'primary-200')));
  $map: map.merge($map, ('bui-button-background-secondary':       map.get($map, 'secondary')));
  $map: map.merge($map, ('bui-button-background-secondary-hover': map.get($map, 'secondary-200')));
  $map: map.merge($map, ('bui-button-background-accent':          map.get($map, 'accent')));
  $map: map.merge($map, ('bui-button-background-accent-hover':    map.get($map, 'accent-200')));
  $map: map.merge($map, ('bui-button-background-error':           map.get($map, 'system-error')));
  $map: map.merge($map, ('bui-button-background-error-hover':     map.get($map, 'system-error-200')));
  $map: map.merge($map, ('bui-button-background-normal':          map.get($map, 'grayscale-800')));
  $map: map.merge($map, ('bui-button-background-normal-hover':    map.get($map, 'grayscale-500')));
  $map: map.merge($map, ('bui-button-background-weak':            map.get($map, 'grayscale-50')));
  $map: map.merge($map, ('bui-button-background-weak-hover':      map.get($map, 'grayscale-200')));
  $map: map.merge($map, ('bui-button-background-inverted':        map.get($map, 'grayscale-A100')));
  $map: map.merge($map, ('bui-button-background-inverted-hover':  map.get($map, 'grayscale-50')));
  $map: map.merge($map, ('bui-button-background-transparent':     map.get($base-theme-palette, 'transparent')));

  $map: map.merge($map, ('bui-button-text-primary':         map.get($map, 'primary')));
  $map: map.merge($map, ('bui-button-text-primary-hover':   map.get($map, 'primary-200')));
  $map: map.merge($map, ('bui-button-text-accent':          map.get($map, 'accent')));
  $map: map.merge($map, ('bui-button-text-accent-hover':    map.get($map, 'accent-200')));
  $map: map.merge($map, ('bui-button-text-tertiary':        map.get($map, 'tertiary')));
  $map: map.merge($map, ('bui-button-text-tertiary-hover':  map.get($map, 'tertiary-200')));
  $map: map.merge($map, ('bui-button-text-link':            map.get($map, 'link-normal')));
  $map: map.merge($map, ('bui-button-text-link-hover':      map.get($map, 'link-hover')));
  $map: map.merge($map, ('bui-button-text-normal':          map.get($map, 'grayscale-500')));
  $map: map.merge($map, ('bui-button-text-normal-hover':    map.get($map, 'grayscale-500')));
  $map: map.merge($map, ('bui-button-text-weak':            map.get($map, 'grayscale-500')));
  $map: map.merge($map, ('bui-button-text-weak-hover':      map.get($map, 'grayscale-200')));
  $map: map.merge($map, ('bui-button-text-inverted':        map.get($map, 'grayscale-A100')));
  $map: map.merge($map, ('bui-button-text-inverted-hover':  map.get($map, 'grayscale-50')));
  $map: map.merge($map, ('bui-button-text-status-success':        var(--color-system-success)));
  $map: map.merge($map, ('bui-button-text-status-success-hover':  var(--color-system-success-200)));
  $map: map.merge($map, ('bui-button-text-status-error':          var(--color-system-error)));
  $map: map.merge($map, ('bui-button-text-status-error-hover':    var(--color-system-error-200)));

  // Table.
  $map: map.merge($map, ('table-head-row-background-normal':   map.get($map, 'primary-100')));
  $map: map.merge($map, ('table-body-row-background':          map.get($map, 'grayscale-A100')));
  $map: map.merge($map, ('table-body-row-background-selected': map.get($map, 'background-weak')));

  // Override all!!!
  $map: map.merge($map, $after-set-colors-map);

  @return $map;
};

$base-theme-colors: colors-list();

:root {
  @include global-helper-mixins.generate-custom-css-properties('color', $base-theme-colors);
}
