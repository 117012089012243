.dx-popup-normal {
  border-radius: 20px;
  min-height: 600px !important;
  padding: 20px 30px 0 30px;
}

.dx-popup-title {
  padding: 0 0 20px 0;
}

.dx-popup-title .dx-closebutton .dx-icon {
  color: #707070;
}

.dx-toolbar-label > div {
  color: var(--color-primary);
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0;
}

.dx-popup-content {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}
