.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border .dx-data-header {
  background-color: var(--color-background-primary-weak);
  border-bottom: 1px solid var(--color-white);
  border-left: 1px solid transparent;
  border-right: 1px solid var(--color-white);
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border .dx-column-header {
  background-color: var(--color-background-primary-weak);
  border-right: 1px solid transparent;
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border .dx-area-description-cell {
  background-color: var(--color-background-primary-weak);
  border-left: 1px solid transparent;
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border .dx-area-description-cell .dx-area-field {
  background-color: var(--color-background-primary-weak);
  border: none;
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border > tr:first-child {
  border-left: 3px solid var(--color-white);
  border-top: 3px solid var(--color-white);
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border > tr:first-child .dx-filter-header .dx-pivotgrid-fields-area .dx-area-field-container .dx-pivotgrid-fields-area-head .dx-area-field {
  background-color: var(--color-background-weak);
  border: 1px solid var(--color-background-weak);
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border > tr .dx-column-header .dx-pivotgrid-fields-area-head .dx-area-field {
  background-color: var(--color-background-primary-weak);
  border: none;
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border > tr .dx-area-description-cell .dx-pivotgrid-fields-area-head .dx-area-field {
  background-color: var(--color-background-primary-weak);
  border: none;
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border > tr .dx-data-header .dx-pivotgrid-fields-area-head .dx-area-field {
  background-color: var(--color-background-primary-weak);
  border: none;
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border > tr:first-child .dx-filter-header .dx-pivotgrid-fields-area .dx-area-field-container {
  border-spacing: 0 8px;
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border > tr {
  color: var(--color-text-weak);
}

.dx-pivotgrid-container .dx-pivotgrid-border {
  display: block;
  overflow: auto;
  width: 100%;
}

.dx-pivotgrid-container .dx-pivotgrid-border .dx-column-header .dx-pivotgrid-fields-area {
  overflow: auto;
}

.dx-pivotgrid-container .dx-pivotgrid-border .dx-column-header .dx-pivotgrid-fields-area .dx-area-field {
  background-color: var(--color-background-primary-weak);
  border: none;
}

.dx-pivotgrid-container .dx-pivotgrid-border .dx-data-header .dx-pivotgrid-fields-area .dx-area-field {
  background-color: var(--color-background-primary-weak);
  border: none;
}

.dx-pivotgrid .dx-column-header .dx-pivotgrid-fields-area-head tr > td:first-child {
  padding-left: 10px;
}

.dx-pivotgrid .dx-pivotgrid-border .dx-filter-header {
  border-bottom: 0;
}

.dx-pivotgrid .dx-pivotgrid-border .dx-area-row-cell {
  border-left: 0;
  border-right: 1px solid var(--color-white);
}

.dx-pivotgrid .dx-filter-header .dx-pivotgrid-toolbar {
  margin-right: 0;
  display: none;
}

.dx-pivotgrid .dx-filter-header .dx-pivotgrid-fields-area-head tr > td:first-child {
  padding-left: 0;
}

.dx-pivotgrid .dx-pivotgrid-expanded .dx-expand::before {
  color: var(--color-text-weak);
}

.dx-pivotgrid .dx-pivotgrid-collapsed .dx-expand {
  color: var(--color-text-weak);
}

.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-collapsed > span {
  color: var(--color-text-strong) !important;
}

.dx-pivotgrid-container td {
  border-right: 1px solid var(--color-white);
  color: var(--color-text-strong) !important;
}

.dx-pivotgrid-fields-container .dx-area-field-content {
  color: var(--color-text-weak);
  font-weight: var(--font-weight-bold);
}

.dx-pivotgrid-fields-container .dx-sort-up {
  color: var(--color-text-weak);
  font-weight: var(--font-weight-bold);
}

.dx-pivotgrid-fields-container .dx-sort-down {
  color: var(--color-text-weak);
  font-weight: var(--font-weight-bold);
}

.dx-pivotgrid-fields-container .dx-header-filter-empty {
  color: var(--color-text-weak);
  font-weight: var(--font-weight-bold);
}

.dx-pivotgrid .dx-pivotgrid-fields-area-head .dx-empty-area-text {
  color: var(--color-text-weak);
  font-weight: var(--font-weight-bold);
  padding-block: 0;
}

.dx-scrollable-container .dx-scrollable-content .dx-pivotgrid-horizontal-headers .dx-pivotgrid-expanded {
  color: var(--color-text-strong);
}

.dx-scrollable-container .dx-scrollable-content .dx-pivotgrid-horizontal-headers .dx-pivotgrid-expanded > span {
  color: var(--color-text-strong);
}

.dx-widget .dx-pivotgrid-container .dx-pivotgrid-border > tr:first-child .dx-filter-header .dx-pivotgrid-fields-area .dx-area-field-container .dx-pivotgrid-fields-area-head tr {
  display: block;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  width: 97vw;
}
