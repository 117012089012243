.c-template-container:has(.c-simple-master-data__wrapper, .c-report-detail-content__wrapper) {
    height: 100%;
}

.c-template-container:has(.c-simple-master-data__wrapper):not(.c-report-detail-content__wrapper) {
  padding-bottom: 0 !important;
}

.c-template-container .c-simple-master-data__wrapper {
  height: 100%;
}

.c-report-detail-content .c-template-container {
  padding-bottom: 0 !important;
}
