@use "sass:map";
@use "../../settings/mixins/helper" as global-helper-mixins;
@use "../base/base.theme" as base-theme;

.theme-beyond-core {
  @include global-helper-mixins.generate-custom-css-properties('color', base-theme.colors-list((
    'pre-set-colors-map': (
      base-theme.map-material-colors('primary', base-theme.$beyond-treepoppy)
    )
  )));
}
