@use "sass:map";
@use "../../settings/mixins/helper" as global-helper-mixins;
@use "../base/base.theme" as base-theme;

.theme-beyond-pos {
  @include global-helper-mixins.generate-custom-css-properties('color', base-theme.colors-list((
    'pre-set-colors-map': (
      base-theme.map-material-colors('primary', base-theme.$beyond-chathamsblue)
    )
  )));
}

.theme-beyond-pos-dark {
  $beyond-shakespeare: (
    50 : #e9f5fb,
    100 : #c8e6f4,
    200 : #a4d5ed,
    300 : #7fc4e6,
    400 : #63b7e0,
    500 : #48aadb,
    600 : #41a3d7,
    700 : #3899d2,
    800 : #3090cd,
    900 : #2f526d,
    A100 : #fdfeff,
    A200 : #cae7ff,
    A400 : #97d1ff,
    A700 : #7ec5ff,
    contrast: (
      50 : #ffffff,
      100 : #ffffff,
      200 : #ffffff,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #ffffff,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
    )
  );

  $pre-set-colors-map: ();

  $pre-set-colors-map: map.merge($pre-set-colors-map, base-theme.map-material-colors('primary', $beyond-shakespeare));

  // Text.
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('text-strong':          map.get(base-theme.$beyond-grayscale, A100)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('text-normal':          map.get(base-theme.$beyond-grayscale, 200)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('text-normal-inverted': map.get(base-theme.$beyond-grayscale, 800)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('text-weak':            map.get(base-theme.$beyond-grayscale, 400)));

  // Link.
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('link-normal': map.get(base-theme.$beyond-info, 400)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('link-hover':  map.get(base-theme.$beyond-info, 200)));

  // Background.
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('background-primary-weak':    var(--color-primary-900)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('background-normal':          map.get(base-theme.$beyond-grayscale, 900)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('background-normal-inverted': map.get(base-theme.$beyond-grayscale, 100)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('background-weak':            map.get(base-theme.$beyond-grayscale, 700)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('background-medium':          map.get(base-theme.$beyond-grayscale, 600)));

  //// Border.
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('border-strongest':       map.get(base-theme.$beyond-grayscale, A100)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('border-strong':         map.get(base-theme.$beyond-grayscale, 400)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('border-normal':          map.get(base-theme.$beyond-grayscale, 700)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('border-normal-inverted': map.get(base-theme.$beyond-grayscale, 900)));
  $pre-set-colors-map: map.merge($pre-set-colors-map, ('border-weak':            map.get(base-theme.$beyond-grayscale, 800)));

  $after-set-colors-map: ();

  // BUI Button.
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-primary':         map.get($pre-set-colors-map, 'primary')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-primary-hover':   map.get($pre-set-colors-map, 'primary-200')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-secondary':       map.get(base-theme.$base-theme-colors, 'secondary')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-secondary-hover': map.get(base-theme.$base-theme-colors, 'secondary-200')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-accent':          map.get(base-theme.$base-theme-colors, 'accent')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-accent-hover':    map.get(base-theme.$base-theme-colors, 'accent-200')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-normal':          map.get(base-theme.$base-theme-colors, 'grayscale-800')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-normal-hover':    map.get(base-theme.$base-theme-colors, 'grayscale-500')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-weak':            map.get(base-theme.$base-theme-colors, 'grayscale-800')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-weak-hover':      map.get(base-theme.$base-theme-colors, 'grayscale-700')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-inverted':        map.get(base-theme.$base-theme-colors, 'grayscale-800')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-inverted-hover':  map.get(base-theme.$base-theme-colors, 'grayscale-700')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-background-transparent':     map.get(base-theme.$base-theme-palette, 'transparent')));

  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-primary':         map.get($pre-set-colors-map, 'primary')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-primary-hover':   map.get($pre-set-colors-map, 'primary-200')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-accent':          map.get(base-theme.$base-theme-colors, 'accent')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-accent-hover':    map.get(base-theme.$base-theme-colors, 'accent-200')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-tertiary':        map.get(base-theme.$base-theme-colors, 'tertiary')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-tertiary-hover':  map.get(base-theme.$base-theme-colors, 'tertiary-200')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-link':            map.get($pre-set-colors-map, 'link-normal')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-link-hover':      map.get($pre-set-colors-map, 'link-hover')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-normal':          map.get(base-theme.$base-theme-colors, 'grayscale-200')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-normal-hover':    map.get(base-theme.$base-theme-colors, 'grayscale-500')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-weak':            map.get(base-theme.$base-theme-colors, 'grayscale-500')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-weak-hover':      map.get(base-theme.$base-theme-colors, 'grayscale-200')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-inverted':        map.get(base-theme.$base-theme-colors, 'grayscale-A100')));
  $after-set-colors-map: map.merge($after-set-colors-map, ('bui-button-text-inverted-hover':  map.get(base-theme.$base-theme-colors, 'grayscale-50')));

  // Table.
  $after-set-colors-map: map.merge($after-set-colors-map, ('table-head-row-background-normal':   var(--color-primary-900)));
  $after-set-colors-map: map.merge($after-set-colors-map, ('table-body-row-background':          var(--color-background-normal)));
  $after-set-colors-map: map.merge($after-set-colors-map, ('table-body-row-background-selected': var(--color-grayscale-800)));

  @include global-helper-mixins.generate-custom-css-properties('color', base-theme.colors-list((
    'pre-set-colors-map': ($pre-set-colors-map),
    'after-set-colors-map': ($after-set-colors-map),
  )));
}
