.mat-menu-item {
  span {
    color: var(--color-text-weak);
  }
  .active {
    span {
      color: var(--color-text-strong);
    }
  }
}
