.u-form-element {
  --u-form-element--color-normal: var(--color-form-input-text-normal);
  --u-form-element--border-radius: calc(var(--u-form-element--height) / 2);
  --u-form-element--font-size: var(--font-size-base, 1rem);
  --u-form-element--height: 40px;
  --u-form-element--padding-x: var(--space-s);
  --u-form-element--space-y: var(--space-m);
  --u-form-element-label--font-size: var(--appearance-font-size-xxs, 13px);
  --u-form-element-label--color: var(--color-text-weak);
  --u-form-element-description--font-size: var(--appearance-font-size-xxxs, 10px);
  --u-form-element-messages--font-size: var(--appearance-font-size-xxs, 13px);
  --u-form-element-messages--color-error: var(--color-system-error);
  --u-form-element-messages--padding-bottom: var(--space-m);
  --u-form-element--border-color: var(--color-form-input-border-normal);
  --u-form-element--shadow-color: #{rgba(#000, 0.3)};
}

.u-form-element__row {
  // Default space.
  &:where(.u-form-element__row--space-s),
  &:where(:not([class*="u-form-element__row--space-"])) {
    --u-form-element-row--space-y: var(--space-s);
  }

  @each $space in ('xs', 's', 'm', 'l', 'xl', 'xxl') {
    &:where(.u-form-element__row--space-#{$space}) {
      --u-form-element-row--space-y: var(--space-#{$space});
    }
  }

  &:not(:first-child),
  &:not(.u-form-element__messages--error) {
    margin-top: var(--u-form-element-row--space-y);
  }

  &:not(:last-of-type),
  &:not(:last-child) {
    margin-bottom: var(--u-form-element-row--space-y);
  }
}


.u-form-element__header {
  display: flex;
  margin-bottom: 2px;
}

.u-form-element__label {
  --u-form-element-label-font-size: var(--font-size-xxs);
  --u-form-element-label-color: var(--color-text-weak);
  color: var(--u-form-element-label-color);
  font-size: var(--u-form-element-label-font-size);
}

.u-form-element__label--block {
  display: block;
}

.u-form-element__body {
  display: flex;
}

.u-form-element__element-prefix {}

.u-form-element__element-wrapper {
  flex-grow: 1;
}

.u-form-element__element {
  appearance: none;
  border: {
    color: var(--u-form-element--border-color);
    radius: var(--u-form-element--border-radius);
    style: solid;
    width: 1px;
  }
  color: var(--u-form-element--color-normal);
  font-size: var(--u-form-element--font-size);
  height: var(--u-form-element--height);
  padding: {
    left:  var(--u-form-element--padding-x);
    right: var(--u-form-element--padding-x);
  }
  width: 100%;

  &:focus {
    box-shadow: 0 0 0 2px var(--u-form-element--shadow-color);
    outline: none;
  }
}

.u-form-element__element-suffix {}

.u-form-element__description {
  font-size: var(--u-form-element-description--font-size);
}

.u-form-element__messages {
  display: flex;
  flex-wrap: nowrap;
  font-size: var(--u-form-element-messages--font-size);
  padding-bottom: var(--u-form-element-messages--padding-bottom);

  &--error {
    color: var(--u-form-element-messages--color-error);
  }
}

.u-form-element__tooltip-icon {
  padding: {
    left: 10px;
    top: 20px;
  }
}

.u-form-element__messages-icon {
  fill: currentColor;
  margin-right: 5px;
  height: 16px;
  width: 16px;
}

.u-form-element__messages-text {
  flex-grow: 1;
}
