.u-mat-full {
  width: 100%;
}

.u-mat-max {
  max-width: 500px;
}

.u-mat-button-square {
  min-width: auto !important;
  padding: {
    left: 0 !important;
    right: 0 !important;
  };
  width: 36px !important;
}

.u-mat-button-text-uppercase .mat-button-wrapper {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.u-mat-menu-item-group {
  align-items: center;
  display: grid;
  grid-template-columns: auto 38px;
  overflow: hidden;
}
