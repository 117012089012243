.mat-input-element::placeholder,
.mat-input-element::-webkit-input-placeholder {
  color: var(--color-text-weak)
}

.mat-form-field-label {
  color: var(--color-text-normal);

  & > * {
    pointer-events: auto;
  }

  & > mat-label {
    column-gap: 0.25em;
    display: inline-flex;
  }
}

.mat-form-field-required-marker {
  color: var(--color-system-error);
}

.mat-form-field .mat-input-element:not(:disabled) {
  caret-color: var(--color-text-normal) !important;
}

.mat-form-field-suffix .mat-icon-button .mat-icon {
  color: var(--color-text-weak);
}

.c-formatting-dialog .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button .mat-icon {
  color: var(--color-text-weak) !important;
}

.c-summaries-dialog__items-wrapper .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
