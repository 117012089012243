@use "sass:meta";

/* You can add global styles to this file, and also import other style files */
@use "init" as *;

@use "settings/css-custom-properties/appearance" as *;

@use "base/color" as *;
@use "base/layout" as *;
@use "base/scrollbar" as *;
@use "base/svg" as *;
@use "base/typography" as *;

@use "components/draggable" as *;
@use "components/form" as *;
@use "components/materialicons" as *;
@use "components/popdv-poreska-prijava" as *;
@use "components/pivot-grid" as *;

// Some styles for DevExtreme components are commented,
// because we don't use them at this moment,
// but a lot of work inside has already been done.
//
// Let them be there at this phase of the project,
// and we can decide later if we will throw them away,
// or we will use them.
//@use "components/dev-extreme/datagrid/actionsheet.scss" as *;
//@use "components/dev-extreme/datagrid/badge.scss" as *;
//@use "components/dev-extreme/datagrid/badge.scss" as *;
//@use "components/dev-extreme/datagrid/button.scss" as *;
//@use "components/dev-extreme/datagrid/calendar.scss" as *;
//@use "components/dev-extreme/datagrid/card.scss" as *;
//@use "components/dev-extreme/datagrid/checkbox.scss" as *;
//@use "components/dev-extreme/datagrid/context-menu.scss" as *;
//@use "components/dev-extreme/datagrid/dateview.scss" as *;
//@use "components/dev-extreme/datagrid/dropdowneditor.scss" as *;
//@use "components/dev-extreme/datagrid/dropdownlist.scss" as *;
//@use "components/dev-extreme/datagrid/field.scss" as *;
//@use "components/dev-extreme/datagrid/filterbuilder.scss" as *;
//@use "components/dev-extreme/datagrid/gridbase.scss" as *;
//@use "components/dev-extreme/datagrid/icons.scss" as *;
//@use "components/dev-extreme/datagrid/layout-manager.scss" as *;
//@use "components/dev-extreme/datagrid/list.scss" as *;
//@use "components/dev-extreme/datagrid/loading.scss" as *;
//@use "components/dev-extreme/datagrid/menu.scss" as *;
//@use "components/dev-extreme/datagrid/numberbox.scss" as *;
//@use "components/dev-extreme/datagrid/placeholder.scss" as *;
//@use "components/dev-extreme/datagrid/popup.scss" as *;
//@use "components/dev-extreme/datagrid/scrollable.scss" as *;
//@use "components/dev-extreme/datagrid/search.scss" as *;
//@use "components/dev-extreme/datagrid/sorting.scss" as *;
//@use "components/dev-extreme/datagrid/tabpanel.scss" as *;
//@use "components/dev-extreme/datagrid/tabs.scss" as *;
//@use "components/dev-extreme/datagrid/texteditor.scss" as *;
//@use "components/dev-extreme/datagrid/theme.scss" as *;
//@use "components/dev-extreme/datagrid/timeview.scss" as *;
//@use "components/dev-extreme/datagrid/toolbar.scss" as *;
//@use "components/dev-extreme/datagrid/treeview.scss" as *;
//@use "components/dev-extreme/datagrid/typography.scss" as *;
//@use "components/dev-extreme/datagrid/validation.scss" as *;
//@use "components/dev-extreme/datagrid/widget.scss" as *;

@use "utilities/cursor" as *;
@use "utilities/material" as *;
@use "utilities/form-elements" as *;
@use "utilities/icons" as *;
@use "utilities/layout" as *;
@use "utilities/lists" as *;
@use "utilities/shadows" as *;
@use "utilities/text" as *;
@use "utilities/visuals" as *;

@use "overrides/flexmonster" as *;
@use "overrides/cdk-overlay" as *;
@use "overrides/chip-action" as *;
@use "overrides/material" as *;
@use "overrides/material-button" as *;
@use "overrides/material-dialog" as *;
@use "overrides/material-menu" as *;
@use "overrides/material-progress-bar" as *;
@use "overrides/material-radio" as *;
@use "overrides/material-select" as *;
@use "overrides/material-slide-toggle" as *;
@use "overrides/material-snack-bar" as *;
@use "overrides/material-tab" as *;
@use "overrides/template-container" as *;

// Foundation.
@use "./foundation/foundation" as *;
@include foundation-everything(true);

@layer settings, base, components, utilities, overrides;

@layer components.devextreme {
  @include meta.load-css("components/dev-extreme/general/dx.light");
  @include meta.load-css("components/dev-extreme/datagrid/pager");

  .c-beyond-table {
    @include meta.load-css("components/dev-extreme/general/dx.common");
    @include meta.load-css("components/dev-extreme/datagrid/datagrid");
  }

  .c-devextreme {
    @include meta.load-css("components/dev-extreme/general/dx.generic.beyond-devextreme-smart-reports");
  }
}

@layer overrides.devextreme {
  @include meta.load-css("overrides/dx-diagram");
  @include meta.load-css("overrides/dx-widget");
  @include meta.load-css("overrides/dx-datagrid-filter-row");

  .c-beyond-table {
    @include meta.load-css("overrides/datagrid");
  }

  .c-layout-content-data-wrapper__body {
    @include meta.load-css("overrides/datagrid-layout");
  }

  .c-tree-list-table {
    @include meta.load-css("overrides/dev-extreme/treelist/dx-treelist");
  }
}

@layer overrides.devextreme.datagrid {
  .c-data-grid__wrapper {
    @include meta.load-css("overrides/dev-extreme/datagrid/datagrid");
  }

  .dx-selectbox-popup-wrapper {
    @include meta.load-css("overrides/dev-extreme/datagrid/selectbox-popup.scss");
  }
}

@layer overrides.devextreme.pivot-datagrid {
  .c-pivot-grid__wrapper {
    @include meta.load-css("overrides/dev-extreme/datagrid/pivotdatagrid");
  }

  .dx-pivotgridfieldchooser {
    @include meta.load-css("overrides/dev-extreme/datagrid/pivotgridfieldchoser");
  }

  .dx-popup-wrapper:not(.dx-selectbox-popup-wrapper) {
    @include meta.load-css("overrides/dev-extreme/datagrid/popup-normal");
  }
}

@layer overrides.devextreme.list-view-reports {
  .c-reports-content__body {
    @include meta.load-css("overrides/dev-extreme/datagrid/list-view-reports");
  }
}
