.mat-progress-bar {
  --mat-progress-bar-buffer-background-color: var(--color-background-weak);
  height: 8px;
  border-radius: 4px;
}

.mat-progress-bar .mat-progress-bar-element::after {
  border-radius: 4px;
}

.material-light-theme .mat-progress-bar-buffer {
  background-color: var(--mat-progress-bar-buffer-background-color);
}

.material-light-theme .mat-progress-bar-background {
  fill: var(--mat-progress-bar-buffer-background-color);
}
