.material-light-theme .mat-snack-bar-container,
.material-dark-theme .mat-snack-bar-container {
  --c-snackbar-message-background: var(--color-background-normal-inverted);
  background-color: var(--c-snackbar-message-background);
}

.material-light-theme .mat-snack-bar--bui-info,
.material-dark-theme .mat-snack-bar--bui-info {
  --c-snackbar-message-background: var(--color-system-info);
}

.material-light-theme .mat-snack-bar--bui-success,
.material-dark-theme .mat-snack-bar--bui-success {
  --c-snackbar-message-background: var(--color-system-success);
}

.material-light-theme .mat-snack-bar--bui-error,
.material-material-theme .mat-snack-bar--bui-error {
  --c-snackbar-message-background: var(--color-system-error);
}
