.c-beyond-table .dx-datagrid-headers {
  border-radius: 0 !important;
}

.c-beyond-table .dx-datagrid .dx-row > td {
  padding: 0 7px !important;
}

.c-beyond-table .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-header-row > td {
  padding-inline: 7px;
}

.c-beyond-table .dx-datagrid .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table .dx-datagrid-filter-row .dx-command-edit {
  background-color: var(--color-white);
}
