.dx-data-row > .column-disabled {
  background-color: #d3d3d3;
}

.dx-datagrid .dx-row > td {
  padding: 0 17px;
}

.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-table {
  height: 100%;
}

.dx-datagrid-headers .dx-datagrid-table {
  height: 100%;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
  background-color: white;
  height: 17px;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
  background-color: white;
  width: 17px;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active {
  height: 17px;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active {
  width: 17px;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll {
  display: inline-flex;
  padding-right: 15px;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
  padding-bottom: 15px;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
  transition: none;
}

.dx-scrollbar-horizontal {
  display: block;
  margin-top: 5px;
  height: 17px;
  background-color: white;
}

.dx-scrollbar-vertical {
  width: 17px;
  background-color: white;
}

.dx-scrollable-container {
  touch-action: pan-y pan-x;
  float: none;
  overflow-x: auto;
  overflow-y: auto;
}

.dx-scrollbar-horizontal .dx-scrollbar-hoverable .dx-state-hover .dx-scrollable-scroll {
  height: 17px;
}

.dx-scrollbar-vertical .dx-scrollbar-hoverable .dx-state-hover .dx-scrollable-scroll {
  width: 17px;
}

.dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed {
  transform: none !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell:not(.dx-command-select) {
  padding: 0 !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor {
  border-radius: 0;
}

.dx-datagrid-filter-row .dx-menu {
  top: 2px !important;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
  border-radius: 0px !important;
}

.dx-datagrid.dx-datagrid-borders > .dx-datagrid-pager {
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  padding: 0;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-scrollable-simulated .dx-datagrid-table .dx-datagrid-filter-row {
  height: 26px !important;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-header-row {
  height: 32px !important;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-header-row > td {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  text-align: left !important;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-header-row .dx-command-select {
  background-color: var(--color-primary-100);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  text-align: center !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row:last-child > td {
  border-bottom-color: var(--color-border-weak);
  border-right: none;
}

.dx-datagrid-headers + .dx-datagrid-rowsview {
  border-top: none !important;
}

.dx-datagrid.dx-filter-menu .dx-menu-item-content .dx-icon {
  font-size: 13px;
}

.dx-datagrid-focus-overlay {
  border: none;
}

.dx-datagrid-borders > .dx-datagrid-headers {
  border-left: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dx-datagrid-headers .dx-texteditor-input {
  min-height: 26px !important;
  padding-block: 0;
}

.dx-datebox-calendar .dx-dropdowneditor-icon::before {
  color: var(--color-grayscale-300);
  margin-top: -8px;
}

.dx-datagrid.dx-filter-menu .dx-menu-item-has-icon .dx-icon {
  color: var(--color-grayscale-300);
}

.dx-datagrid-checkbox-size .dx-checkbox-icon {
  border: 2px solid var(--color-text-weak);
  border-radius: 3px;
}

.dx-datagrid-rowsview .dx-row {
  height: 30px !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td:first-of-type .dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
  background-color: var(--color-system-success);
  border: 1px solid var(--color-system-success);
  color: var(--color-white);
}

.dx-datagrid-content .dx-datagrid-table .dx-data-row .dx-command-edit-with-icons .dx-link-delete {
  color: var(--color-grayscale) !important;
}

.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 0 0 0 5px;
}

.dx-datebox-calendar .dx-dropdowneditor-icon {
  font-size: 16px;
}

.dx-pager.dx-light-mode .dx-page-sizes {
  align-items: center;
  color: var(--color-text-weak);
  display: flex;
  flex-direction: row-reverse;
  font-size: var(--font-size-xxxxxs);
  gap: var(--space-xs);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-color: var(--color-background-medium);
    top: 8px;
    right: 0;
    width: 1px;
    height: 20px;
    display: inline-block;
  }
}

.dx-pager .dx-pages {
  display: flex;
  align-items: center;
}

.dx-pager .dx-pages .dx-page-indexes {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-xs);
}

.dx-pager .dx-light-pages .dx-info-text,
.dx-pager .dx-light-pages .dx-pages-count {
  display: none;
}

.dx-pager .dx-pages .dx-info {
  margin-inline: 20px;
  font-size: var(--font-size-xxxxxs);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-color: var(--color-background-medium);
    top: -5px;
    right: -20px;
    width: 1px;
    height: 20px;
    display: inline-block;
  }
}

.dx-pager .dx-pages .dx-next-button::before,
.dx-pager .dx-pages .dx-prev-button::before {
  color: var(--color-text-weak);
  font-size: var(--font-size-xxxs) !important;
  height: 22px;
  line-height: 22px;
}

.dx-dropdowneditor-icon::before {
  color: var(--color-grayscale-300);
}

.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
  background-color: var(--color-system-success);
  border: 1px solid var(--color-system-success);
}

.dx-checkbox-checked .dx-checkbox-icon::before {
  color: var(--color-white);
}

.dx-pager input:not(:disabled) {
  color: var(--color-text-weak) !important;
  font-size: var(--font-size-xxxxxs);
  padding-top: 0;
}

.dx-pager .dx-page-index .dx-texteditor-container {
  height: 23px;
}

.dx-pager .dx-page-index .dx-texteditor-container input:not(:disabled) {
  text-align: center;
  padding-bottom: 8px;
}

.dx-pager .dx-pages .beyond-table__left-arrow-icon .c-bui-button-icon__content {
  height: 20px;
  width: 20px;
}

.dx-pager .dx-pages #rightArrowBtn .c-bui-button-icon__content {
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 425px) {
  .dx-datagrid.dx-datagrid-borders > .dx-datagrid-pager {
    flex-direction: column;
  }

  .dx-pager .dx-pages {
    justify-content: flex-end;
  }

  .dx-pager.dx-light-mode .dx-page-sizes::after {
    display: none;
  }

  .dx-dropdowneditor.dx-dropdowneditor-field-clickable {
    margin-right: 0;
  }
}
