@supports (-moz-appearance:none) {
  .cdk-overlay-connected-position-bounding-box
  {
    position: absolute !important;
    left: var(--space-m) !important;
    right: var(--space-m) !important;
    width: auto !important;
  }
}

.cdk-overlay-connected-position-bounding-box:has(.c-master-data-records-filters__overlay-panel) {
  left: var(--space-m) !important;
  right: var(--space-m) !important;
  width: auto !important;
}

.cdk-overlay-connected-position-bounding-box:has(.c-report-detail-content__overlay-panel) {
  left: var(--space-m) !important;
  right: var(--space-m) !important;
  width: auto !important;
}

