@font-face {
  font-family: 'Roboto';
  src: url('../../../font/roboto/roboto-300.eot');
  src: url('../../../font/roboto/roboto-300.eot?#iefix') format('embedded-opentype'),
       url('../../../font/roboto/roboto-300.woff2') format('woff2'),
       url('../../../font/roboto/roboto-300.woff') format('woff'),
       url('../../../font/roboto/roboto-300.ttf') format('truetype'),
       url('../../../font/roboto/roboto-300.svg#Roboto') format('svg');
  font-weight: 300;
  font-style: normal;
}
